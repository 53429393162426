import { UUID } from "gx-npm-lib";
import { createContext, ReactElement, useState } from "react";
import { SurveyDrawerView } from "./app.constants";
import { AppContextValue, InitFileDto, ProductDto } from "./app.types";

const AppContext = createContext<AppContextValue>({} as AppContextValue);
const AppContextProvider = ({ children }: { children: ReactElement }) => {
  const [buildersInstructionFileIds, setBuildersInstructionFileIds] = useState<UUID[]>([]);
  const [buildersRequestedDocFileId, setBuildersRequestedDocFileId] = useState<UUID>("");
  const [files, setFiles] = useState<InitFileDto[]>([]);
  const [products, setProducts] = useState<ProductDto[]>([]);
  const [requestedDocName, setRequestedDocName] = useState<string>("");
  const [rowIdCollapsing, setRowIdCollapsing] = useState<UUID>("");
  const [rowIdHighlighted, setRowIdHighlighted] = useState<UUID>("");
  const [rowIdOpenMenu, setRowIdOpenMenu] = useState<UUID>("");
  const [selectedInstructionFileIds, setSelectedInstructionFileIds] = useState<UUID[]>([]);
  const [selectedRequestedDocFileId, setSelectedRequestedDocFileId] = useState<UUID>("");
  const [surveyDrawerView, setSurveyDrawerView] = useState<SurveyDrawerView | "">("");
  const [requestedDocId, setRequestedDocId] = useState<UUID>("");

  const value = {
    buildersInstructionFileIds,
    buildersRequestedDocFileId,
    files,
    products,
    requestedDocName,
    rowIdCollapsing,
    rowIdHighlighted,
    rowIdOpenMenu,
    selectedInstructionFileIds,
    selectedRequestedDocFileId,
    surveyDrawerView,
    requestedDocId,
    setBuildersInstructionFileIds,
    setBuildersRequestedDocFileId,
    setFiles,
    setProducts,
    setRequestedDocName,
    setRowIdCollapsing,
    setRowIdHighlighted,
    setRowIdOpenMenu,
    setSelectedInstructionFileIds,
    setSelectedRequestedDocFileId,
    setSurveyDrawerView,
    setRequestedDocId,
  };
  return <AppContext.Provider value={value}>{children}</AppContext.Provider>;
};

export { AppContext, AppContextProvider };

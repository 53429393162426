import classNames from "classnames";
import { useContext } from "react";
import { SurveyDrawerView } from "../../../app.constants";
import { AppContext } from "../../../app.context";
import DateCellComponent from "./cells/date-cell/date-cell.component";
import NameCellComponent from "./cells/name-cell/name-cell.component";
import OptionsCellComponent from "./cells/options-cell/options-cell.component";
import OwnerCellComponent from "./cells/owner-cell/owner-cell.component";
import ProductSelectInputCellComponent from "./cells/product-select-input-cell/product-select-input-cell.component";
import SurveyInstructionsCellComponent from "./cells/survey-instructions-cell/survey-instructions-cell.component";
import SurveyRequestedAttachmentCellComponent from "./cells/survey-requested-attachment-cell/survey-requested-attachment-cell.component";
import styles from "./files-table-rows.styles.module.scss";

const FilesTableRowsComponent = () => {
  const { files, rowIdCollapsing, rowIdHighlighted, rowIdOpenMenu, surveyDrawerView } = useContext(AppContext);
  return (
    <tbody className={styles.root}>
      {files.map((file) => {
        return (
          <tr
            key={file.fileId}
            className={classNames(
              styles.row,
              rowIdCollapsing === file.fileId && styles.collapsing,
              rowIdHighlighted === file.fileId && styles.highlighted,
              rowIdOpenMenu === file.fileId && styles.openMenu
            )}
          >
            {surveyDrawerView === SurveyDrawerView.INSTRUCTION_FILES && (
              <SurveyInstructionsCellComponent fileId={file.fileId} />
            )}
            {surveyDrawerView === SurveyDrawerView.REQUESTED_DOC_FILE && (
              <SurveyRequestedAttachmentCellComponent fileId={file.fileId} />
            )}
            <NameCellComponent fileName={file.fileName} surveyRequestedDocName={file.surveyRequestedDocName} />
            <ProductSelectInputCellComponent
              fileId={file.fileId}
              fileInitProdId={file.initProductId}
              fileSurveyId={file.surveyId}
            />
            <OwnerCellComponent email={file.uploaderEmail} name={file.uploaderName} surveyId={file.surveyId} />
            <DateCellComponent date={file.uploadDate as unknown as string /* todo fix typing */} />
            <OptionsCellComponent
              fileId={file.fileId}
              fileInitProdId={file.initProductId}
              fileName={file.fileName}
              isAttachedToSurvey={file.isAttachedToSurvey}
              surveyId={file.surveyId}
              surveyProductDocId={file.surveyProductDocId}
            />
          </tr>
        );
      })}
    </tbody>
  );
};

export default FilesTableRowsComponent;

import { useMediaQuery } from "@mui/material";
import classNames from "classnames";
import { colorPalette } from "gx-npm-common-styles";
import {
  getAsyncRequest,
  InitProdState,
  InitUserRole,
  useCaptureEventsV2,
  useScrolling,
  useUserInitAccess,
  useUserState,
  UUID,
} from "gx-npm-lib";
import { IconButton, SnackbarBanner, TooltipV2, TypographyComponent, UsernameDisplay } from "gx-npm-ui";
import { Fragment, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { AppCustomEvent, ClientEvent } from "../../app.constants";
import { FileHubParams, InitFileDto, InitFileGetResponse, ProductDto } from "../../app.types";
import DownloadIcon from "../download.icon";
import DeleteDialogComponent from "./delete-dialog/delete-dialog.component";
import FileExtensionIconDisplayComponent from "./file-extension-icon-display/file-extension-icon-display.component";
import KebabMenuComponent from "./kebab-menu/kebab-menu.component";
import ProductSelectComponent from "./product-select/product-select.component";
import EmptyFileListSVG from "./empty-file-list.svg";
import VendorProvidedIcon from "./vendor-provided.icon";
import styles from "./file-list.styles.module.scss";

const DELETE_COLLAPSE_TIMER_MS = 300;
const FILE_INPUT_HEIGHT = 148;
const FILE_INPUT_MARGIN = 48;
const FILE_LIST_TITLE_HEIGHT = 96;
const HEADER_STICKY_DEPTH = 86;
const HEADER_STICKY_DEPTH_STACKED_VIEW = FILE_INPUT_HEIGHT + FILE_INPUT_MARGIN + FILE_LIST_TITLE_HEIGHT;

const FileListComponent = () => {
  const { t } = useTranslation();
  const captureEvents = useCaptureEventsV2();
  const [files, setFiles] = useState<InitFileDto[]>([]);
  const [fileIdOpenKebab, setFileIdOpenKebab] = useState<UUID>("");
  const [fileIdOpenSelect, setFileIdOpenSelect] = useState<UUID>("");
  const [fileIdRowHovered, setFileIdRowHovered] = useState<UUID>("");
  const [fileIdToCollapse, setFileIdToCollapse] = useState<UUID>("");
  const [fileIdToDelete, setFileIdToDelete] = useState<UUID>("");
  const [hasError, setHasError] = useState(false);
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
  const [productsInEval, setProductsInEval] = useState<ProductDto[]>([]);
  const [productsNotInEval, setProductsNotInEval] = useState<ProductDto[]>([]);
  const [showSuccessSnackbar, setShowSuccessSnackbar] = useState(false);
  const [successSnackbarMessage, setSuccessSnackbarMessage] = useState("");
  const { initId = "", initProdId = "" } = useParams<FileHubParams>();
  const { role } = useUserInitAccess(initId);
  const { firstName, lastName } = useUserState();
  const isStackedView = useMediaQuery("(max-width:1240px)");
  const isListHeaderStuck = useScrolling(isStackedView ? HEADER_STICKY_DEPTH_STACKED_VIEW : HEADER_STICKY_DEPTH);

  useEffect(() => {
    const handleCustomEvent = (event: CustomEvent) => {
      if (!event.detail) {
        return;
      }
      const newFile = {
        fileId: event.detail.fileId as UUID,
        fileName: event.detail.fileName as string,
        initProductId: event.detail.initProdId as UUID,
        surveyId: "" as UUID,
        surveyProductDocId: "" as UUID,
        surveyRequestedDocName: "",
        uploadDate: new Date(),
        uploaderEmail: "",
        uploaderName: `${firstName} ${lastName}`,
        isAttachedToSurvey: false,
      };
      setFiles((prevState) => [newFile, ...prevState]);
    };

    window.addEventListener(AppCustomEvent.SUCCESSFUL_FILE_UPLOADED, handleCustomEvent);
    return () => {
      window.removeEventListener(AppCustomEvent.SUCCESSFUL_FILE_UPLOADED, handleCustomEvent);
    };
  }, [firstName, lastName]);

  useEffect(() => {
    if (!initId) {
      return;
    }
    (async () => {
      const url = initProdId
        ? `/api/v2/initiatives/${initId}/files/product/${initProdId}`
        : `/api/v2/initiatives/${initId}/files`;
      try {
        const response: InitFileGetResponse = await getAsyncRequest(url);
        if (response.status !== 200 || !Array.isArray(response.data?.data?.files)) {
          throw new Error();
        }
        setFiles(response.data.data.files);
        setProductsInEval(
          response.data.data.products.filter((prod) => {
            return [InitProdState.IN_EVALUATION, InitProdState.AWARDED].includes(prod.state);
          })
        );
        setProductsNotInEval(
          response.data.data.products.filter((prod) => {
            return [InitProdState.LISTED, InitProdState.SCREENED_OUT].includes(prod.state);
          })
        );
      } catch (error) {
        setHasError(true);
      }
    })();
  }, [initId, initProdId]);

  const handleChangeProduct = (fileId: UUID, initProductId: UUID) => {
    if (!initProdId) {
      setFiles((prev) => prev.map((file) => (file.fileId !== fileId ? file : { ...file, initProductId })));
      return;
    }

    setFiles((prev) => prev.filter((file) => file.fileId !== fileId));
    const assignedProduct = [...productsInEval, ...productsNotInEval].find(
      (product) => product.initProductId === initProductId
    );
    if (!assignedProduct) {
      return;
    }
    setSuccessSnackbarMessage(`${t("Your document has been reassigned to")} ${assignedProduct.name}.`);
    setShowSuccessSnackbar(true);
  };

  const handleDeleteSuccess = () => {
    if (!fileIdToDelete) {
      return;
    }
    setFileIdToCollapse(fileIdToDelete);
    setTimeout(() => {
      setFiles((prev) => prev.filter((file) => file.fileId !== fileIdToDelete));
      setFileIdToCollapse("");
      setFileIdToDelete("");
    }, DELETE_COLLAPSE_TIMER_MS);
  };

  const handleClickDownload = async (fileId: UUID) => {
    const file = files.find((f) => f.fileId === fileId);
    if (!file) {
      return;
    }

    const url = !file.surveyId
      ? `/api/v2/initiatives/${initId}/file/${fileId}/download`
      : `/api/v2/initiatives/${initId}/survey-documents/evaluator/${file.surveyId}/${file.initProductId}/documents/${file.surveyProductDocId}/download`;

    try {
      const response = await getAsyncRequest(url);
      if (response.status !== 200 || !response.data?.data?.signedUrl) {
        throw new Error("Failed to retrieve the signed URL");
      }
      window.open(response.data.data.signedUrl, "_blank", "noopener");

      const metaData = {
        initiativeId: initId,
        fileId,
        ...(initProdId ? { initProductId: initProdId } : {}),
        ...(file.surveyId ? { surveyId: file.surveyId } : {}),
        ...(file.surveyProductDocId ? { surveyProductDocId: file.surveyProductDocId } : {}),
      };
      captureEvents([{ eventType: ClientEvent.INITIATIVE_FILE_HUB_FILE_DOWNLOADED, metaData }]);
    } catch (error) {
      setHasError(true);
    }
  };

  return (
    <Fragment>
      <div className={styles.fileListRoot}>
        <div className={styles.fileCount}>
          <TypographyComponent styling="p3" boldness="medium">
            {t("Documents")} ({files.length})
          </TypographyComponent>
        </div>
        <div
          className={classNames(styles.spacer, initProdId && styles.productTab, files.length === 0 && styles.noFiles)}
        />
        <div className={styles.body}>
          <table className={styles.fileTable} aria-label={t("File list")}>
            <thead
              className={classNames(
                styles.tableHeader,
                isListHeaderStuck && styles.stuckHeader,
                files.length === 0 && styles.emptyList,
                initProdId && styles.productTab
              )}
            >
              <tr>
                <th scope="col">
                  <TypographyComponent boldness="medium" color="iron" rootClassName={styles.columnName} styling="p4">
                    {t("Name")}
                  </TypographyComponent>
                </th>
                <th scope="col">
                  <TypographyComponent boldness="medium" color="iron" rootClassName={styles.columnVendor} styling="p4">
                    {t("Vendor")}
                  </TypographyComponent>
                </th>
                <th scope="col">
                  <TypographyComponent boldness="medium" color="iron" rootClassName={styles.columnOwner} styling="p4">
                    {t("Owner")}
                  </TypographyComponent>
                </th>
                <th scope="col">
                  <TypographyComponent boldness="medium" color="iron" rootClassName={styles.columnDate} styling="p4">
                    {t("Date added")}
                  </TypographyComponent>
                </th>
                <th className={classNames(styles.spacerRight, files.length === 0 && styles.noFiles)} scope="col">
                  <div className={styles.hiddenColumnText}>{t("options column")}</div>
                </th>
              </tr>
            </thead>
            <tbody>
              {files.length === 0 && (
                <tr>
                  <td colSpan={5}>
                    <div className={styles.emptyState}>
                      <img alt={t("Empty document list")} className={styles.emptyFile} src={EmptyFileListSVG} />
                      <TypographyComponent color="iron" styling="h4">
                        {t("No documents added")}
                      </TypographyComponent>
                      <TypographyComponent boldness="regular" color="iron" styling="p3">
                        {t("Your team's document library is empty. Once documents are uploaded, they'll appear here.")}
                      </TypographyComponent>
                    </div>
                  </td>
                </tr>
              )}
              {files.length > 0 &&
                files.map((file) => {
                  const fileParts = file.fileName.split(".");
                  const fileName = fileParts.slice(0, -1).join(".");
                  const fileExt = fileParts[fileParts.length - 1].toLocaleLowerCase();
                  return (
                    <tr
                      key={file.fileId}
                      className={classNames(
                        styles.fileRow,
                        (fileIdOpenKebab === file.fileId || fileIdOpenSelect === file.fileId) && styles.open,
                        fileIdToCollapse === file.fileId && styles.collapse
                      )}
                      onFocus={() => setFileIdRowHovered(file.fileId)}
                      onMouseLeave={() => setFileIdRowHovered("")}
                      onMouseOver={() => setFileIdRowHovered(file.fileId)}
                    >
                      <td className={styles.namesContainer} aria-label={t("File name and type")}>
                        <FileExtensionIconDisplayComponent fileExt={fileExt} />
                        <div>
                          <TypographyComponent boldness="regular" styling="p5">
                            {file.surveyRequestedDocName}
                          </TypographyComponent>
                          <div className={styles.namesTypeStyles}>
                            <TypographyComponent boldness="medium" rootClassName={styles.fileName} styling="p3">
                              {fileName}
                              <TypographyComponent boldness="regular" color="iron" element="span" styling="p4">
                                {` (.${fileExt})`}
                              </TypographyComponent>
                            </TypographyComponent>
                          </div>
                        </div>
                      </td>
                      <td aria-label={t("Select Vendor")} className={styles.vendor}>
                        <ProductSelectComponent
                          fileId={file.fileId}
                          initProdId={file.initProductId}
                          isDisabled={!!file.surveyId || role === InitUserRole.VIEWER}
                          isRowHovered={fileIdRowHovered === file.fileId}
                          onChange={(initProductId: UUID) => handleChangeProduct(file.fileId, initProductId)}
                          onClose={() => {
                            setFileIdOpenSelect("");
                            setFileIdRowHovered("");
                          }}
                          onOpen={() => setFileIdOpenSelect(file.fileId)}
                          productsInEval={productsInEval}
                          productsNotInEval={productsNotInEval}
                        />
                      </td>
                      <td aria-label={t("Owner avatar")}>
                        {file.surveyId && (
                          <TooltipV2
                            placement="top"
                            PopperProps={{ modifiers: { offset: { offset: "0px, 8px" } } }}
                            title={
                              <Fragment>
                                <TypographyComponent boldness="medium" color="coal" styling="p4">
                                  {file.uploaderName}
                                </TypographyComponent>
                                <TypographyComponent boldness="medium" color="coal" styling="p4">
                                  {file.uploaderEmail}
                                </TypographyComponent>
                              </Fragment>
                            }
                          >
                            <div className={styles.vendorProvidedIcon}>
                              <VendorProvidedIcon />
                            </div>
                          </TooltipV2>
                        )}
                        {!file.surveyId && (
                          <TooltipV2
                            placement="top"
                            PopperProps={{ modifiers: { offset: { offset: "0px, 8px" } } }}
                            rootClassName={styles.userNameToolTip}
                            title={file.uploaderName || ""}
                          >
                            <div className={styles.userNameIconContainer}>
                              <UsernameDisplay
                                isSmallerIcon={true}
                                name={file.uploaderName}
                                rootClassName={styles.userNameIcon}
                                showName={false}
                              />
                            </div>
                          </TooltipV2>
                        )}
                      </td>
                      <td aria-label={t("Date added")}>
                        <TypographyComponent boldness="regular" color="carbon" rootClassName={styles.date} styling="p4">
                          {new Date(file.uploadDate).toLocaleDateString("en-US", {
                            day: "numeric",
                            month: "short",
                            year: "numeric",
                          })}
                        </TypographyComponent>
                      </td>
                      <td aria-label={t("Kebab menu")}>
                        {![InitUserRole.CONTRIBUTOR, InitUserRole.OWNER].includes(role as InitUserRole) && (
                          <IconButton ariaLabel={t("download")} onClick={() => handleClickDownload(file.fileId)}>
                            <DownloadIcon fill={colorPalette.neutrals.iron.hex} />
                          </IconButton>
                        )}
                        {[InitUserRole.CONTRIBUTOR, InitUserRole.OWNER].includes(role as InitUserRole) && (
                          <KebabMenuComponent
                            isSurvey={!!file.surveyId}
                            onClickDownload={() => handleClickDownload(file.fileId)}
                            onClickDelete={() => {
                              setFileIdToDelete(file.fileId);
                              setIsDeleteDialogOpen(true);
                            }}
                            onClose={() => setFileIdOpenKebab("")}
                            onOpen={() => setFileIdOpenKebab(file.fileId)}
                          />
                        )}
                        <DeleteDialogComponent
                          fileId={file.fileId}
                          fileName={fileName}
                          isAttachedToSurvey={file.isAttachedToSurvey}
                          isOpen={isDeleteDialogOpen && fileIdToDelete === file.fileId}
                          onClose={() => setIsDeleteDialogOpen(false)}
                          onDeleteSuccess={handleDeleteSuccess}
                        />
                      </td>
                    </tr>
                  );
                })}
            </tbody>
          </table>
        </div>
      </div>
      <SnackbarBanner
        isOpen={showSuccessSnackbar}
        message={successSnackbarMessage}
        setIsOpen={setShowSuccessSnackbar}
        type="SUCCESS"
      />
      <SnackbarBanner isDefaultErrorMessage={true} isOpen={hasError} setIsOpen={setHasError} type="ERROR" />
    </Fragment>
  );
};

export default FileListComponent;

import { UUID } from "gx-npm-lib";
import { RadioButton } from "gx-npm-ui";
import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { AppContext } from "../../../../../app.context";

const SurveyRequestedAttachmentCellComponent = ({ fileId = "" }: { fileId: UUID }) => {
  const { t } = useTranslation();
  const [checked, setChecked] = useState<boolean>(false);
  const { selectedRequestedDocFileId, setSelectedRequestedDocFileId } = useContext(AppContext);

  useEffect(() => {
    setChecked(selectedRequestedDocFileId === fileId);
  }, [fileId, selectedRequestedDocFileId]);

  const handleClick = () => {
    setSelectedRequestedDocFileId(checked ? "" : fileId);
  };

  return (
    <td aria-label={t("include file with questionnaire")}>
      <RadioButton
        checked={checked}
        inputProps={{
          "aria-checked": checked,
          "aria-label": checked
            ? t("click to remove from requested document")
            : t("click to attach to requested document"),
        }}
        onClick={handleClick}
      />
    </td>
  );
};

export default SurveyRequestedAttachmentCellComponent;

import { Menu, MenuItem } from "@mui/material";
import { colorPalette } from "gx-npm-common-styles";
import { DeleteTrashcanIcon, KebabSingleIcon } from "gx-npm-icons";
import { IconButton, TooltipV2, TypographyComponent, useFeatureFlag } from "gx-npm-ui";
import { FC, MouseEvent, useState } from "react";
import { useTranslation } from "react-i18next";
import { GCOM_4101__docManagementV2 } from "../../../lib/feature-flags";
import styles from "./kebab-menu.styles.module.scss";

type KebabMenuProps = {
  isSurvey?: boolean;
  onClickDelete: () => void;
  onClickDownload: () => void;
  onClose: () => void;
  onOpen: () => void;
};
const KebabMenuComponent: FC<KebabMenuProps> = ({
  isSurvey = false,
  onClickDelete = () => {},
  onClickDownload = () => {},
  onClose = () => {},
  onOpen = () => {},
}) => {
  const { t } = useTranslation();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const isDocMgmtV2FFOn = useFeatureFlag(GCOM_4101__docManagementV2);

  const handleClickDelete = () => {
    if (isSurvey) {
      return;
    }
    onClickDelete();
    handleClose();
  };

  const handleClickDownload = () => {
    onClickDownload();
    handleClose();
  };

  const handleClickOpen = (event: MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
    onOpen();
  };

  const handleClose = () => {
    setAnchorEl(null);
    onClose();
  };

  return (
    <div className={styles.kebabMenu}>
      <IconButton ariaLabel="options" hover={Boolean(anchorEl)} onClick={handleClickOpen}>
        <KebabSingleIcon />
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        classes={{ paper: styles.muiMenuPaper, root: styles.muiMenuRoot }}
        keepMounted={true}
        onClose={handleClose}
        open={Boolean(anchorEl)}
      >
        <MenuItem
          classes={
            isDocMgmtV2FFOn
              ? { gutters: styles.muiMenuItemGuttersV2, root: styles.muiMenuItemRootV2 }
              : { root: styles.muiMenuItemRoot }
          }
          onClick={handleClickDownload}
        >
          <TypographyComponent styling="p3" boldness="medium">
            {t("Download")}
          </TypographyComponent>
        </MenuItem>
        <div className={styles.divider} />
        <TooltipV2
          deactivate={!isSurvey}
          placement="left"
          title={t("Documents provided in a questionnaire response cannot be deleted.")}
        >
          <div>
            <MenuItem
              classes={
                isDocMgmtV2FFOn
                  ? { gutters: styles.muiMenuItemGuttersV2, root: styles.muiMenuItemRootV2 }
                  : { root: styles.muiMenuItemDeleteRoot }
              }
              disabled={isSurvey}
              onClick={handleClickDelete}
            >
              <DeleteTrashcanIcon
                fillPath={isSurvey ? colorPalette.neutrals.stone.hex : colorPalette.neutrals.iron.hex}
              />
              <TypographyComponent boldness="medium" color={isSurvey ? "iron" : "carbon"} styling="p3">
                {t("Delete")}
              </TypographyComponent>
            </MenuItem>
          </div>
        </TooltipV2>
      </Menu>
    </div>
  );
};

export default KebabMenuComponent;

import { useMediaQuery } from "@mui/material";
import classNames from "classnames";
import { useScrolling } from "gx-npm-lib";
import { TypographyComponent } from "gx-npm-ui";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { FileHubParams } from "../../../app.types";
import styles from "./files-table-header.styles.module.scss";

const FILE_INPUT_HEIGHT = 148;
const FILE_INPUT_MARGIN = 48;
const FILE_LIST_TITLE_HEIGHT = 96;
const FULL_PAGE_HEADER_STICKY_DEPTH = 86;
const FULL_PAGE_HEADER_STICKY_DEPTH_STACKED_VIEW = FILE_INPUT_HEIGHT + FILE_INPUT_MARGIN + FILE_LIST_TITLE_HEIGHT;

const FilesTableHeaderComponent = () => {
  const { t } = useTranslation();
  const { initProdId = "", surveyId = "" } = useParams<FileHubParams>();
  const isStackedView = useMediaQuery("(max-width:1240px)");
  const fullPageDepth = isStackedView ? FULL_PAGE_HEADER_STICKY_DEPTH_STACKED_VIEW : FULL_PAGE_HEADER_STICKY_DEPTH;
  const isFullPageHeaderStuck = useScrolling(fullPageDepth);
  return (
    <thead
      className={classNames(
        styles.root,
        initProdId && styles.prodProfileTab,
        surveyId && styles.slideIn,
        // todo impl stuck drawer header
        isFullPageHeaderStuck && !surveyId && styles.stuckShadow
      )}
    >
      <tr>
        {!!surveyId && (
          <th
            aria-label={t("selected file(s) column")}
            className={styles.selectFilesHeader}
            role="columnheader"
            scope="col"
          >
            <div />
          </th>
        )}
        <th className={classNames(styles.nameHeader, !surveyId && styles.rounded)} role="columnheader" scope="col">
          <TypographyComponent boldness="medium" color="iron" styling="p4">
            {t("Name")}
          </TypographyComponent>
        </th>
        <th className={styles.selectProductHeader} role="columnheader" scope="col">
          <TypographyComponent boldness="medium" color="iron" styling="p4">
            {t("Vendor")}
          </TypographyComponent>
        </th>
        <th className={styles.ownerHeader} role="columnheader" scope="col">
          <TypographyComponent boldness="medium" color="iron" styling="p4">
            {t("Owner")}
          </TypographyComponent>
        </th>
        <th className={styles.dateHeader} role="columnheader" scope="col">
          <TypographyComponent boldness="medium" color="iron" styling="p4">
            {t("Date added")}
          </TypographyComponent>
        </th>
        <th aria-label={t("options column")} className={styles.optionsHeader} role="columnheader" scope="col">
          <div />
        </th>
      </tr>
    </thead>
  );
};

export default FilesTableHeaderComponent;

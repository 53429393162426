const ChevronLeftIcon = () => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M16.6186 2.99006C16.1286 2.50006 15.3386 2.50006 14.8486 2.99006L6.53859 11.3001C6.14859 11.6901 6.14859 12.3201 6.53859 12.7101L14.8486 21.0201C15.3386 21.5101 16.1286 21.5101 16.6186 21.0201C17.1086 20.5301 17.1086 19.7401 16.6186 19.2501L9.37859 12.0001L16.6286 4.75006C17.1086 4.27006 17.1086 3.47006 16.6186 2.99006Z"
        fill="#0000A9"
      />
    </svg>
  );
};

export default ChevronLeftIcon;

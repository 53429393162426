import { TooltipV2, UsernameDisplay } from "gx-npm-ui";
import styles from "./eval-team-avatar.styles.module.scss";

const EvalTeamAvatarComponent = ({ name = "" }: { name: string }) => {
  return (
    <TooltipV2 placement="top" PopperProps={{ modifiers: { offset: { offset: "0px, 8px" } } }} title={name}>
      <div className={styles.container}>
        <UsernameDisplay isSmallerIcon={true} name={name} rootClassName={styles.usernameDisplayRoot} showName={false} />
      </div>
    </TooltipV2>
  );
};

export default EvalTeamAvatarComponent;

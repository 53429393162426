import { TooltipV2, TypographyComponent } from "gx-npm-ui";
import { Fragment } from "react";
import VendorProvidedIcon from "./vendor-provided.icon";
import styles from "./survey-recipient-avatar.styles.module.scss";

type SurveyRecipientAvatarProps = { email: string; name: string };
const SurveyRecipientAvatarComponent = ({ email = "", name = "" }: SurveyRecipientAvatarProps) => {
  return (
    <TooltipV2
      placement="top"
      PopperProps={{ modifiers: { offset: { offset: "0px, 8px" } } }}
      title={
        <Fragment>
          <TypographyComponent boldness="medium" color="coal" styling="p4">
            {name}
          </TypographyComponent>
          <TypographyComponent boldness="medium" color="coal" styling="p4">
            {email}
          </TypographyComponent>
        </Fragment>
      }
    >
      <div className={styles.iconWrapper}>
        <VendorProvidedIcon />
      </div>
    </TooltipV2>
  );
};

export default SurveyRecipientAvatarComponent;

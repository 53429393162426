import { Drawer } from "@mui/material";
import classNames from "classnames";
import {
  SurveySetupExistingInstructionFilesMessage,
  SurveySetupExistingRequestedDocFileMessage,
  SurveySetupStateName,
  useCaptureEventsV2,
} from "gx-npm-lib";
import { createStateSubscription, destroyStateSubject, destroyStateSubscription } from "gx-npm-messenger-util";
import { Button, TypographyComponent } from "gx-npm-ui";
import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { navigateToUrl } from "single-spa";
import { ClientEvent, SurveyDrawerView } from "../../app.constants";
import { AppContext } from "../../app.context";
import { FileHubParams } from "../../app.types";
import FileDragAndDropUploadComponent from "../../sections/file-drag-and-drop-upload/file-drag-and-drop-upload.component";
import FilesTableComponent from "../../sections/files-table/files-table.component";
import FileHubLegalBannerComponent from "../../sections/file-hub-legal-banner/file-hub-legal-banner.component";
import SurveyDrawerFooterComponent from "../../sections/survey-drawer-footer/survey-drawer-footer.component";
import ChevronLeftIcon from "./chevron-left.icon";
import GartnerBuySmartLogoSVG from "./gartner-buysmart.logo.svg";
import styles from "./survey-drawer-view.styles.module.scss";

const TRANSITION_DURATION_MS = 1000;
const SurveyDrawerViewComponent = () => {
  const { t } = useTranslation();
  const captureEvents = useCaptureEventsV2();
  const [isOpen, setIsOpen] = useState(false);
  const {
    setBuildersInstructionFileIds,
    setBuildersRequestedDocFileId,
    setRequestedDocName,
    setSelectedInstructionFileIds,
    setSelectedRequestedDocFileId,
    setSurveyDrawerView,
    setRequestedDocId,
  } = useContext(AppContext);
  const { initId = "", surveyId = "" } = useParams<FileHubParams>();

  useEffect(() => {
    setIsOpen(true);
  }, []);

  useEffect(() => {
    const name = SurveySetupStateName.SURVEY_SETUP_EXISTING_INSTRUCTION_FILES;
    const callback = (message: SurveySetupExistingInstructionFilesMessage) => {
      setBuildersInstructionFileIds(message?.fileIds || []);
      setSelectedInstructionFileIds(message?.fileIds || []);
      setSurveyDrawerView(SurveyDrawerView.INSTRUCTION_FILES);
      if (!initId || !surveyId) {
        return;
      }
      const eventType = ClientEvent.INITIATIVE_FILE_HUB_PAGE_VIEWED;
      const metaData = { initiativeId: initId, surveyId, surveyView: SurveyDrawerView.INSTRUCTION_FILES };
      captureEvents([{ eventType, metaData }]);
    };
    const subscription = createStateSubscription(name, callback);
    return () => {
      destroyStateSubscription(subscription);
    };
  }, [
    captureEvents,
    initId,
    surveyId,
    setBuildersInstructionFileIds,
    setSelectedInstructionFileIds,
    setSurveyDrawerView,
  ]);

  useEffect(() => {
    const name = SurveySetupStateName.SURVEY_SETUP_EXISTING_REQUESTED_DOC_FILE;
    const callback = (message: SurveySetupExistingRequestedDocFileMessage) => {
      setRequestedDocId(message?.requestedDocId || "");
      setRequestedDocName(message?.requestedDocName || "");
      setBuildersRequestedDocFileId(message?.attachedFileId || "");
      setSelectedRequestedDocFileId(message?.attachedFileId || "");
      setSurveyDrawerView(SurveyDrawerView.REQUESTED_DOC_FILE);
      if (!initId || !surveyId) {
        return;
      }
      const eventType = ClientEvent.INITIATIVE_FILE_HUB_PAGE_VIEWED;
      const metaData = { initiativeId: initId, surveyId, surveyView: SurveyDrawerView.REQUESTED_DOC_FILE };
      captureEvents([{ eventType, metaData }]);
    };
    const subscription = createStateSubscription(name, callback);
    return () => {
      destroyStateSubscription(subscription);
    };
  }, [
    captureEvents,
    initId,
    surveyId,
    setBuildersRequestedDocFileId,
    setRequestedDocId,
    setRequestedDocName,
    setSelectedRequestedDocFileId,
    setSurveyDrawerView,
  ]);

  const handleClose = () => {
    setIsOpen(false);
    destroyStateSubject(SurveySetupStateName.SURVEY_SETUP_EXISTING_INSTRUCTION_FILES);
    destroyStateSubject(SurveySetupStateName.SURVEY_SETUP_EXISTING_REQUESTED_DOC_FILE);
    setTimeout(() => {
      navigateToUrl(`/s/evaluation/${initId}/questionnaire/setup/${surveyId}`);
    }, TRANSITION_DURATION_MS);
  };

  return (
    <Drawer
      anchor="right"
      classes={{ paperAnchorRight: styles.muiDrawerPaperAnchorRight }}
      onClose={handleClose}
      open={isOpen}
      transitionDuration={TRANSITION_DURATION_MS}
    >
      <aside aria-label={t("document management")} className={styles.root}>
        <div>
          <div className={styles.buttonHeader}>
            <Button
              ariaLabel={t("close")}
              onClick={handleClose}
              rootClassName={classNames(styles.backButton, "btn-tertiary")}
            >
              <ChevronLeftIcon />
              {t("BACK TO CUSTOMIZE QUESTIONNAIRE")}
            </Button>
            <img alt={t("Gartner BuySmart logo")} className={styles.gartnerBuySmartLogo} src={GartnerBuySmartLogoSVG} />
          </div>
          <div className={styles.titleHeader}>
            <TypographyComponent boldness="medium" color="carbon" element="h1" styling="h5">
              {t("Evaluation documents")}
            </TypographyComponent>
          </div>
        </div>
        <div className={styles.filesContentWrapper}>
          <div className={styles.dragDropWrapper}>
            <FileDragAndDropUploadComponent />
          </div>
          <FilesTableComponent />
          <FileHubLegalBannerComponent />
        </div>
        <div className={styles.footerWrapper}>
          <SurveyDrawerFooterComponent onClose={handleClose} />
        </div>
      </aside>
    </Drawer>
  );
};

export default SurveyDrawerViewComponent;

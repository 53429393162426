import { FileExtensionBadgeComponent, TypographyComponent, ValidFileExtension } from "gx-npm-ui";
import styles from "./name-cell.styles.module.scss";

type NameCellProps = { fileName: string; surveyRequestedDocName: string };
const NameCellComponent = ({ fileName = "", surveyRequestedDocName = "" }: NameCellProps) => {
  const fileParts = fileName.split(".");
  const fileNameWithoutExtension = fileParts.slice(0, -1).join(".");
  const fileExtension = fileParts[fileParts.length - 1].toLocaleLowerCase();
  return (
    <td className={styles.root}>
      <FileExtensionBadgeComponent extension={fileExtension as ValidFileExtension} />
      <div className={styles.name}>
        {surveyRequestedDocName && (
          <TypographyComponent boldness="regular" color="iron" styling="p5">
            {surveyRequestedDocName}
          </TypographyComponent>
        )}
        <TypographyComponent boldness="medium" color="carbon" styling="p4">
          {fileNameWithoutExtension}
          {` `}
          <TypographyComponent boldness="regular" color="iron" element="span" styling="p4">
            (.{fileExtension})
          </TypographyComponent>
        </TypographyComponent>
      </div>
    </td>
  );
};

export default NameCellComponent;

import { UUID } from "gx-npm-lib";
import EvalTeamAvatarComponent from "./eval-team-avatar/eval-team-avatar.component";
import SurveyRecipientAvatarComponent from "./survey-recipient-avatar/survey-recipient-avatar.component";

type OwnerCellProps = { email: string; name: string; surveyId: UUID };
const OwnerCellComponent = ({ email = "", name = "", surveyId = "" }: OwnerCellProps) => {
  return (
    <td>
      {surveyId && <SurveyRecipientAvatarComponent email={email} name={name} />}
      {!surveyId && <EvalTeamAvatarComponent name={name} />}
    </td>
  );
};

export default OwnerCellComponent;

import {
  postAsyncRequest,
  SurveySetupDeletedFileMessage,
  SurveySetupStateName,
  useCaptureEventsV2,
  UUID,
} from "gx-npm-lib";
import { updateStateSubject } from "gx-npm-messenger-util";
import { Button, ButtonLoader, Dialog, SnackbarBanner, TypographyComponent } from "gx-npm-ui";
import { Fragment, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { ClientEvent } from "../../../app.constants";
import { FileHubParams } from "../../../app.types";
import styles from "./delete-dialog.styles.module.scss";

type DeleteDialogProps = {
  fileId: UUID;
  fileName: string;
  isAttachedToSurvey: boolean;
  isOpen: boolean;
  onClose: () => void;
  onDeleteSuccess: () => void;
};
const DeleteDialog = ({
  fileName = "",
  fileId,
  isAttachedToSurvey = false,
  isOpen = false,
  onClose = () => {},
  onDeleteSuccess = () => {},
}: DeleteDialogProps) => {
  const { t } = useTranslation();
  const [hasError, setHasError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const { initId = "", initProdId = "" } = useParams<FileHubParams>();
  const captureEvents = useCaptureEventsV2();

  const handleConfirmDelete = async () => {
    setIsLoading(true);
    try {
      const response = await postAsyncRequest(`/api/v2/initiatives/${initId}/file/${fileId}/delete`);
      if (response.status !== 201) {
        throw new Error();
      }
      if (isAttachedToSurvey) {
        const message: SurveySetupDeletedFileMessage = { fileId };
        updateStateSubject(SurveySetupStateName.SURVEY_SETUP_DELETED_FILE, message);
      }

      const metaData = { initiativeId: initId, fileId, ...(initProdId ? { initProductId: initProdId } : {}) };
      captureEvents([{ eventType: ClientEvent.INITIATIVE_FILE_HUB_FILE_DELETED, metaData }]);
      onDeleteSuccess();
      onClose();
    } catch (error) {
      setHasError(true);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Fragment>
      <Dialog
        open={isOpen}
        handleClose={onClose}
        title={t("Delete document")}
        body={
          <Fragment>
            <TypographyComponent styling="p3" rootClassName={styles.bodyText}>
              <TypographyComponent>
                {t(`Are you sure you want to delete ${fileName}? `)}
                {isAttachedToSurvey
                  ? t(`This document is attached to a questionnaire.`)
                  : t(`This document will be permanently deleted.`)}
              </TypographyComponent>
            </TypographyComponent>
          </Fragment>
        }
        footer={
          <div className={styles.cancelBtn}>
            <Button onClick={onClose} rootClassName="btn-tertiary">
              {t("CANCEL")}
            </Button>
            <div className={styles.confirmBtn}>
              <ButtonLoader
                btnClass="primary-destructive-btn"
                disabled={isLoading}
                isLoading={isLoading}
                onClick={handleConfirmDelete}
              >
                {t("DELETE")}
              </ButtonLoader>
            </div>
          </div>
        }
      />
      <SnackbarBanner isDefaultErrorMessage={true} isOpen={hasError} setIsOpen={setHasError} type="ERROR" />
    </Fragment>
  );
};

export default DeleteDialog;

import { TypographyComponent } from "gx-npm-ui";
import { useTranslation } from "react-i18next";
import EmptyTableSVG from "./empty-table.svg";
import styles from "./files-table-empty-body.styles.module.scss";

const FilesTableEmptyBodyComponent = () => {
  const { t } = useTranslation();
  return (
    <div className={styles.root}>
      <img alt={t("Empty document list")} src={EmptyTableSVG} />
      <TypographyComponent color="iron" rootClassName={styles.emptyHeading} styling="h4">
        {t("No documents added")}
      </TypographyComponent>
      <TypographyComponent boldness="regular" color="iron" styling="p3">
        {t("Your team's document library is empty. Once documents are uploaded, they'll appear here.")}
      </TypographyComponent>
    </div>
  );
};

export default FilesTableEmptyBodyComponent;

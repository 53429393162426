import { ExclamationCircledIconV2, RefreshIcon } from "gx-npm-icons";
import { Button, TypographyComponent, useFeatureFlag } from "gx-npm-ui";
import { useTranslation } from "react-i18next";
import { GCOM_4101__docManagementV2 } from "../../../lib/feature-flags";
import { ACCEPTED_FILE_EXTENSIONS, MAX_FILE_MB_SIZE } from "../file-drag-and-drop-upload.constants";
import styles from "./upload-error.styles.module.scss";

type UploadErrorProps = { onClickReset: () => void };
const UploadErrorComponent = ({ onClickReset = () => {} }: UploadErrorProps) => {
  const { t } = useTranslation();
  const isDocMgmtV2FFOn = useFeatureFlag(GCOM_4101__docManagementV2);
  return (
    <div className={styles.errorRoot}>
      <div className={styles.errorHeader}>
        <ExclamationCircledIconV2 />
        <TypographyComponent
          boldness="medium"
          color="poisonCherry"
          element={isDocMgmtV2FFOn ? "h2" : "h3"}
          rootClassName={styles.errorHeaderText}
          styling="p3"
        >
          {t("Document upload failed")}
        </TypographyComponent>
      </div>
      <div className={styles.errorSubtext}>
        <TypographyComponent boldness="regular" color="iron" styling="p4">
          {t("Check document types")}
        </TypographyComponent>
        <TypographyComponent rootClassName={styles.errorFileTypes} boldness="regular" color="iron" styling="p4">
          ({ACCEPTED_FILE_EXTENSIONS})
        </TypographyComponent>
        <TypographyComponent color="iron" element="span" rootClassName={styles.errorSpacer} styling="p4">
          {`|`}
        </TypographyComponent>
        <TypographyComponent boldness="regular" color="iron" styling="p4">
          {`${t("Max size")} ${MAX_FILE_MB_SIZE}MB`}
        </TypographyComponent>
      </div>
      <div className={styles.resetButtonWrapper}>
        <Button rootClassName={styles.resetButton} onClick={onClickReset}>
          <RefreshIcon />
          {t("RESET")}
        </Button>
      </div>
    </div>
  );
};

export default UploadErrorComponent;

import { updateStateSubject } from "gx-npm-messenger-util";
import {
  SurveySetupStateName,
  SurveySetupUpdatedInstructionFilesMessage,
  SurveySetupUpdatedRequestedDocFileMessage,
} from "gx-npm-lib";
import { Button, TypographyComponent } from "gx-npm-ui";
import { useContext } from "react";
import { useTranslation } from "react-i18next";
import { SurveyDrawerView } from "../../app.constants";
import { AppContext } from "../../app.context";
import RequestedDocIcon from "./requested-doc.icon";
import styles from "./survey-drawer-footer.styles.module.scss";

type SurveyDrawerFooterProps = { onClose: () => void };
const SurveyDrawerFooterComponent = ({ onClose = () => {} }: SurveyDrawerFooterProps) => {
  const { t } = useTranslation();
  const {
    buildersInstructionFileIds,
    buildersRequestedDocFileId,
    files,
    requestedDocName,
    selectedInstructionFileIds,
    selectedRequestedDocFileId,
    surveyDrawerView,
    requestedDocId,
  } = useContext(AppContext);

  const handleInstructionFileUpdates = () => {
    if (buildersInstructionFileIds.length === selectedInstructionFileIds.length) {
      const sortedBuilderArr = buildersInstructionFileIds.slice().sort();
      const sortedSelectedArr = selectedInstructionFileIds.slice().sort();
      if (sortedBuilderArr.every((id, idx) => id === sortedSelectedArr[idx])) {
        return;
      }
    }
    const updatedFiles = files
      .filter((f) => selectedInstructionFileIds.includes(f.fileId))
      .map((f) => ({ id: f.fileId, name: f.fileName }));
    const message: SurveySetupUpdatedInstructionFilesMessage = { files: updatedFiles };
    updateStateSubject(SurveySetupStateName.SURVEY_SETUP_UPDATED_INSTRUCTION_FILES, message);
  };

  const handleRequestedDocFileUpdate = () => {
    if (buildersRequestedDocFileId === selectedRequestedDocFileId) {
      return;
    }
    const message: SurveySetupUpdatedRequestedDocFileMessage = { fileId: "", fileName: "", requestDocId: "" };
    const file = files.find((f) => f.fileId === selectedRequestedDocFileId);
    if (file) {
      message.fileId = file.fileId;
      message.fileName = file.fileName;
      message.requestDocId = requestedDocId;
    }
    updateStateSubject(SurveySetupStateName.SURVEY_SETUP_UPDATED_REQUESTED_DOC_FILE, message);
  };

  const handleConfirm = () => {
    if (surveyDrawerView === SurveyDrawerView.INSTRUCTION_FILES) {
      handleInstructionFileUpdates();
    } else if (surveyDrawerView === SurveyDrawerView.REQUESTED_DOC_FILE) {
      handleRequestedDocFileUpdate();
    }
    onClose();
  };

  const hasInstructionFiles = selectedInstructionFileIds.length > 0;
  return (
    <div className={styles.root}>
      {surveyDrawerView === SurveyDrawerView.INSTRUCTION_FILES && (
        <TypographyComponent
          boldness="medium"
          color={hasInstructionFiles ? "darkPear" : "iron"}
          rootClassName={hasInstructionFiles ? styles.instructionFilesAdded : styles.instructionFilesEmpty}
          styling="p3"
        >
          {hasInstructionFiles
            ? `${selectedInstructionFileIds.length} ${t("documents selected")}`
            : t("No documents selected")}
        </TypographyComponent>
      )}
      {surveyDrawerView === SurveyDrawerView.REQUESTED_DOC_FILE && (
        <div className={styles.reqDocText}>
          <RequestedDocIcon />
          <TypographyComponent
            boldness="medium"
            color="sweetHuckleberry"
            rootClassName={styles.reqDocCopy}
            styling="p3"
          >
            {t("You can only attach one document to")}
            {` `}
            {requestedDocName || t("Untitled document")}
            {`.`}
          </TypographyComponent>
        </div>
      )}
      <div className={styles.btnContainer}>
        <Button onClick={onClose}>{t("CANCEL")}</Button>
        <Button onClick={handleConfirm} rootClassName="btn-primary">
          {surveyDrawerView === SurveyDrawerView.INSTRUCTION_FILES && t("ATTACH DOCUMENT(S)")}
          {surveyDrawerView === SurveyDrawerView.REQUESTED_DOC_FILE && t("ATTACH DOCUMENT")}
        </Button>
      </div>
    </div>
  );
};

export default SurveyDrawerFooterComponent;

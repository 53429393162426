import { Loader, TypographyComponent, useFeatureFlag } from "gx-npm-ui";
import { useTranslation } from "react-i18next";
import { GCOM_4101__docManagementV2 } from "../../../lib/feature-flags";
import styles from "./upload-processing.styles.module.scss";

const UploadProcessingComponent = () => {
  const { t } = useTranslation();
  const isDocMgmtV2FFOn = useFeatureFlag(GCOM_4101__docManagementV2);
  return (
    <div className={styles.processingWrapper}>
      <Loader rootClassName={styles.processingLoader} />
      <TypographyComponent
        boldness="medium"
        color="carbon"
        element={isDocMgmtV2FFOn ? "h2" : "h3"}
        styling="p3"
        rootClassName={styles.processingText}
      >
        {t("Uploading your documents")}
      </TypographyComponent>
    </div>
  );
};

export default UploadProcessingComponent;

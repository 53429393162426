import { InitProdState, UUID } from "gx-npm-lib";
import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { DELETE_ROW_COLLAPSE_TIMER_MS } from "../../../../../app.constants";
import { AppContext } from "../../../../../app.context";
import { FileHubParams, ProductDto } from "../../../../../app.types";
import ProductSelectComponent from "../../../../file-list/product-select/product-select.component";

type ProductSelectInputCellProps = { fileId: UUID; fileInitProdId: UUID; fileSurveyId: UUID };
const ProductSelectInputCellComponent = ({
  fileId = "",
  fileInitProdId = "",
  fileSurveyId = "",
}: ProductSelectInputCellProps) => {
  const { t } = useTranslation();
  const { products, setFiles, setRowIdCollapsing, setRowIdOpenMenu } = useContext(AppContext);
  const { initProdId: paramsInitProdId = "", surveyId: paramsSurveyId = "" } = useParams<FileHubParams>();
  const [prodsInEval, setProdsInEval] = useState<ProductDto[]>([]);
  const [prodsNotInEval, setProdsNotInEval] = useState<ProductDto[]>([]);

  useEffect(() => {
    setProdsInEval(products.filter((p) => [InitProdState.IN_EVALUATION, InitProdState.AWARDED].includes(p.state)));
    setProdsNotInEval(products.filter((p) => [InitProdState.LISTED, InitProdState.SCREENED_OUT].includes(p.state)));
  }, [products]);

  const handleChangeProduct = (initProdId: UUID = "") => {
    setFiles((prev) => {
      return prev.map((p) => (p.fileId !== fileId ? p : { ...p, initProductId: initProdId }));
    });
    if (!paramsInitProdId) {
      return;
    }
    setRowIdCollapsing(fileId);
    setTimeout(() => {
      setFiles((prev) => prev.filter((p) => p.fileId !== fileId));
      setRowIdCollapsing("");
    }, DELETE_ROW_COLLAPSE_TIMER_MS);
  };

  return (
    <td aria-label={t("Select vendor")}>
      <ProductSelectComponent
        fileId={fileId}
        initProdId={fileInitProdId}
        isDisabled={!!fileSurveyId || !!paramsSurveyId}
        isRowHovered={false}
        onChange={handleChangeProduct}
        onClose={() => setRowIdOpenMenu("")}
        onOpen={() => setRowIdOpenMenu(fileId)}
        productsInEval={prodsInEval}
        productsNotInEval={prodsNotInEval}
        tooltipText={paramsSurveyId ? t("Documents cannot be modified during questionnaire setup.") : ""}
      />
    </td>
  );
};

export default ProductSelectInputCellComponent;

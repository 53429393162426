import { getAsyncRequest, InitUserRole, useCaptureEventsV2, useUserInitAccess, UUID } from "gx-npm-lib";
import { IconButton, SnackbarBanner } from "gx-npm-ui";
import { colorPalette } from "gx-npm-common-styles";
import { useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { ClientEvent, DELETE_ROW_COLLAPSE_TIMER_MS } from "../../../../../app.constants";
import { AppContext } from "../../../../../app.context";
import { FileHubParams } from "../../../../../app.types";
import DeleteDialog from "../../../../file-list/delete-dialog/delete-dialog.component";
import KebabMenuComponent from "../../../../file-list/kebab-menu/kebab-menu.component";
import DownloadIcon from "../../../../download.icon";

type OptionsCellProps = {
  fileId: UUID;
  fileInitProdId: UUID;
  fileName: string;
  isAttachedToSurvey: boolean;
  surveyId: UUID;
  surveyProductDocId: UUID;
};
const OptionsCellComponent = ({
  fileId = "",
  fileInitProdId = "",
  fileName = "",
  isAttachedToSurvey = false,
  surveyId = "",
  surveyProductDocId = "",
}: OptionsCellProps) => {
  const { t } = useTranslation();
  const [hasDownloadError, setHasDownloadError] = useState<boolean>(false);
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState<boolean>(false);
  const {
    setFiles,
    setRowIdCollapsing,
    setRowIdOpenMenu,
    setSelectedInstructionFileIds,
    setSelectedRequestedDocFileId,
  } = useContext(AppContext);
  const { initId = "", initProdId: paramsInitProdId = "" } = useParams<FileHubParams>();
  const { role } = useUserInitAccess(initId);
  const captureEvents = useCaptureEventsV2();

  const handleClickDownload = async () => {
    const url = !surveyId
      ? `/api/v2/initiatives/${initId}/file/${fileId}/download`
      : `/api/v2/initiatives/${initId}/survey-documents/evaluator/${surveyId}/${fileInitProdId}/documents/${surveyProductDocId}/download`;

    try {
      const response = await getAsyncRequest(url);
      if (response.status !== 200 || !response.data?.data?.signedUrl) {
        throw new Error("Failed to retrieve the signed URL");
      }
      window.open(response.data.data.signedUrl, "_blank", "noopener");

      const metaData = {
        initiativeId: initId,
        fileId,
        ...(paramsInitProdId ? { initProductId: paramsInitProdId } : {}),
        ...(surveyId ? { surveyId } : {}),
        ...(surveyProductDocId ? { surveyProductDocId } : {}),
      };
      captureEvents([{ eventType: ClientEvent.INITIATIVE_FILE_HUB_FILE_DOWNLOADED, metaData }]);
    } catch (error) {
      setHasDownloadError(true);
    }
  };

  const handleDeleteSuccess = () => {
    setRowIdCollapsing(fileId);
    setTimeout(() => {
      setFiles((prev) => prev.filter((p) => p.fileId !== fileId));
      setSelectedInstructionFileIds((prev) => prev.filter((p) => p !== fileId));
      setSelectedRequestedDocFileId((prev) => (prev === fileId ? "" : prev));
      setRowIdCollapsing("");
    }, DELETE_ROW_COLLAPSE_TIMER_MS);
  };

  return (
    <td>
      {![InitUserRole.CONTRIBUTOR, InitUserRole.OWNER].includes(role as InitUserRole) && (
        <IconButton ariaLabel={t("download")} onClick={() => handleClickDownload()}>
          <DownloadIcon fill={colorPalette.neutrals.iron.hex} />
        </IconButton>
      )}
      {[InitUserRole.CONTRIBUTOR, InitUserRole.OWNER].includes(role as InitUserRole) && (
        <KebabMenuComponent
          isSurvey={!!surveyId}
          onClickDelete={() => setIsDeleteDialogOpen(true)}
          onClickDownload={handleClickDownload}
          onClose={() => setRowIdOpenMenu("")}
          onOpen={() => setRowIdOpenMenu(fileId)}
        />
      )}
      <DeleteDialog
        fileId={fileId}
        fileName={fileName}
        isAttachedToSurvey={isAttachedToSurvey}
        isOpen={isDeleteDialogOpen}
        onClose={() => setIsDeleteDialogOpen(false)}
        onDeleteSuccess={handleDeleteSuccess}
      />
      <SnackbarBanner isDefaultErrorMessage={true} isOpen={hasDownloadError} setIsOpen={setHasDownloadError} />
    </td>
  );
};

export default OptionsCellComponent;

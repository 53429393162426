import { messengerFlagLoader } from "gx-npm-lib";
import { FeatureFlagProvider } from "gx-npm-ui";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import ProductTabViewComponent from "./views/product-tab/product-tab-view.component";
import StandAloneViewComponent from "./views/stand-alone/stand-alone-view.component";
import SurveyDrawerViewComponent from "./views/survey-drawer/survey-drawer-view.component";
import { AppContextProvider } from "./app.context";

const Root = () => {
  return (
    <FeatureFlagProvider loader={messengerFlagLoader()}>
      <AppContextProvider>
        <BrowserRouter>
          <Routes>
            <Route path="/s/evaluation/:initId/files/all" element={<StandAloneViewComponent />} />
            <Route path="/s/evaluation/:initId/product/:initProdId/files" element={<ProductTabViewComponent />} />
            <Route path="/s/evaluation/:initId/questionnaire/setup/files" element={<SurveyDrawerViewComponent />} />
            <Route
              path="/s/evaluation/:initId/questionnaire/setup/:surveyId/files"
              element={<SurveyDrawerViewComponent />}
            />
          </Routes>
        </BrowserRouter>
      </AppContextProvider>
    </FeatureFlagProvider>
  );
};

export default Root;

import classNames from "classnames";
import { getAsyncRequest } from "gx-npm-lib";
import { SnackbarBanner, TypographyComponent } from "gx-npm-ui";
import { Fragment, useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useParams } from "react-router-dom";
import { AppContext } from "../../app.context";
import { FileHubParams, InitFileGetResponse } from "../../app.types";
import FilesTableEmptyBodyComponent from "./files-table-empty-body/files-table-empty-body.component";
import FilesTableHeaderComponent from "./files-table-header/files-table-header.component";
import FilesTableRowsComponent from "./files-table-rows/files-table-rows.component";
import styles from "./files-table.styles.module.scss";

const FilesTableComponent = () => {
  const { t } = useTranslation();
  const [hasError, setHasError] = useState<boolean>(false);
  const { files, setFiles, setProducts } = useContext(AppContext);
  const { pathname = "" } = useLocation();
  const { initId = "", initProdId = "", surveyId = "" } = useParams<FileHubParams>();

  useEffect(() => {
    if (!initId) {
      return;
    }
    (async () => {
      let url = `/api/v2/initiatives/${initId}/files`;
      if (pathname.includes("questionnaire/setup")) {
        url = `/api/v2/initiatives/${initId}/files/survey`;
      } else if (initProdId) {
        url = `/api/v2/initiatives/${initId}/files/product/${initProdId}`;
      }
      try {
        const response: InitFileGetResponse = await getAsyncRequest(url);
        if (response.status !== 200 || !response.data?.data) {
          throw new Error();
        }
        setFiles(response.data.data.files || []);
        setProducts(response.data.data.products || []);
      } catch (error) {
        setHasError(true);
      }
    })();
  }, [initId, initProdId, pathname, setFiles, setProducts]);

  return (
    <Fragment>
      <TypographyComponent boldness="medium" color="carbon" element="h2" rootClassName={styles.heading} styling="p3">
        {t("Documents")} {`(${files.length || "0"})`}
      </TypographyComponent>
      <div className={classNames(styles.spacer, initProdId && styles.prodTab, surveyId && styles.slideIn)} />
      <div className={styles.tableWrapper}>
        <table
          className={styles.table}
          {...(files.length === 0 ? { "aria-label": t("currently no files available") } : {})}
        >
          <FilesTableHeaderComponent />
          {files.length > 0 && <FilesTableRowsComponent />}
        </table>
        {files.length === 0 && <FilesTableEmptyBodyComponent />}
        <SnackbarBanner isDefaultErrorMessage={true} isOpen={hasError} setIsOpen={setHasError} />
      </div>
    </Fragment>
  );
};

export default FilesTableComponent;

import { UUID } from "gx-npm-lib";
import { Checkbox } from "gx-npm-ui";
import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { AppContext } from "../../../../../app.context";

const SurveyInstructionsCellComponent = ({ fileId = "" }: { fileId: UUID }) => {
  const { t } = useTranslation();
  const [checked, setChecked] = useState<boolean>(false);
  const { selectedInstructionFileIds, setSelectedInstructionFileIds } = useContext(AppContext);

  useEffect(() => {
    setChecked(selectedInstructionFileIds.includes(fileId));
  }, [fileId, selectedInstructionFileIds]);

  const handleChange = () => {
    if (checked) {
      setSelectedInstructionFileIds((prev) => prev.filter((id) => id !== fileId));
    } else {
      setSelectedInstructionFileIds((prev) => [...prev, fileId]);
    }
  };

  return (
    <td aria-label={t("include file with questionnaire")}>
      <Checkbox
        checked={checked}
        inputProps={{
          "aria-checked": checked,
          "aria-label": checked
            ? t("click to remove from instruction documents")
            : t("click to attach to instruction documents"),
        }}
        onChange={handleChange}
      />
    </td>
  );
};

export default SurveyInstructionsCellComponent;

import { TypographyComponent } from "gx-npm-ui";

const DateCellComponent = ({ date = "" }: { date: string }) => {
  return (
    <td>
      <TypographyComponent boldness="regular" color="carbon" styling="p4">
        {new Date(date).toLocaleDateString("en-US", { day: "numeric", month: "short", year: "numeric" })}
      </TypographyComponent>
    </td>
  );
};

export default DateCellComponent;
